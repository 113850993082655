import React from 'react';
import Config from '../../config';
import Ajax from '../../common/ajax';
import PlanExpirationButton from './PlanExpirationButton';
import StorageInfo from '../../account/StorageInfo';
import Panel from '../../common/containers/Panel';
import { withNamespaces } from 'react-i18next';
import { canCancelPlan } from '../../context/global';
import FreshDesk from '../../integrations/FreshDesk';

class CurrentPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPlan: null,
      processingCurrentPlan: false,
    };
  }

  componentDidMount() {
    this.fetchCurrentPlan();
  }

  fetchCurrentPlan() {
    this.setState({
      ...this.state,
      processingCurrentPlan: true,
    });
    const url = `${Config.apiHost}checkouts/current_plan/`;
    Ajax.get(url).done((currentPlan) => {
      this.setState({
        ...this.state,
        processingCurrentPlan: false,
        currentPlan: currentPlan,
      });
    });
  }

  render() {
    const { i18n } = this.props;
    return (
      <Panel
        title={i18n.t('Meu plano atual')}
        isProcessing={this.state.processingCurrentPlan}
      >
        <h3>{this.state.currentPlan && this.state.currentPlan.name}</h3>
        <h4>
          <StorageInfo />
        </h4>
        {this.state.currentPlan && <PlanExpirationButton />}
        {canCancelPlan() && (
          <>
            <hr />
            <FreshDesk
              label={
                <span className="label label-danger">
                  {i18n.t('Cancelar minha compra')}
                </span>
              }
              showIcon={false}
            />
          </>
        )}
      </Panel>
    );
  }
}

export default withNamespaces()(CurrentPlan);
